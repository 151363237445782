import React, { useReducer, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import { FileError } from 'react-dropzone';
import UrlService from 'mangools-commons/lib/services/UrlService';
import { isNil } from 'ramda';
import Alert from 'react-s-alert';

import { CustomizeReportForm } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelForm';
import { CustomizeReportPanelPreview } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelPreview';

import { UserWhitelabelState } from 'types/state/userReducer';
import { errorToggleWhitelabelStatusSelector, whiteLabelSettingsSelector } from 'selectors/userSelectors';

import { HTTP, HTTPS } from 'constants/Protocols';
import { clearErrorToggleWhitelabelAction, requestedToggleWhitelabelAction } from 'actions/userActions';

interface Values {
    logoFile: File | null;
    logo: string;
    name: string;
    website: string;
    customDomain: string;
    email: string;
    accentColor: string;
    enabled: boolean;
    protocol: typeof HTTP;
}
interface Touched {
    logoFile: boolean;
    name: boolean;
    website: boolean;
    customDomain: boolean;
    email: boolean;
    accentColor: boolean;
    enabled: boolean;
    protocol: boolean;
}

interface Errors {
    logoFile: FileError | null;
    name: string | null;
    website: string | null;
    customDomain: string | null;
    email: string | null;
    accentColor: string | null;
}

type CustomizeReportFormSetValueActionType = keyof Values extends infer K
    ? K extends keyof Values
        ? { type: 'SET_FIELD_VALUE'; payload: { field: K; value: Values[K] } }
        : never
    : never;

type CustomizeReportFormSetTouchedActionType = keyof Touched extends infer K
    ? K extends keyof Touched
        ? { type: 'SET_FIELD_TOUCHED'; payload: { field: K } }
        : never
    : never;

type CustomizeReportFormSetErrorActionType = keyof Errors extends infer K
    ? K extends keyof Errors
        ? { type: 'SET_FIELD_ERROR'; payload: { field: K; value: Errors[K] } }
        : never
    : never;

export type CustomizeReportFormActionType =
    | CustomizeReportFormSetValueActionType
    | CustomizeReportFormSetTouchedActionType
    | CustomizeReportFormSetErrorActionType;

export type CustomizeReportFormState = {
    values: Values;
    touched: Touched;
    errors: Errors;
    firstTouch: boolean;
};

type UserWhitelabelWithoutLogo = Omit<UserWhitelabelState, 'logoUrl'>;

const initReducer = (settings: UserWhitelabelWithoutLogo) => {
    const protocol = UrlService.getProtocol(settings.website) === 'http' ? HTTP : HTTPS;

    return {
        values: {
            ...settings,
            logoFile: null,
            protocol,
            website: UrlService.getWithoutProtocol(settings.website),
        },
        touched: {
            logoFile: false,
            name: false,
            website: false,
            email: false,
            accentColor: false,
            enabled: false,
            protocol: false,
            customDomain: false,
        },
        errors: {
            logoFile: null,
            name: null,
            website: null,
            email: null,
            accentColor: null,
            customDomain: null,
        },
        firstTouch: true,
    };
};
export const CustomizeReportPanelContainer: React.FC<unknown> = () => {
    const reduxDispatch = useDispatch();
    const settings: UserWhitelabelWithoutLogo = useSelector<UserWhitelabelWithoutLogo, UserWhitelabelWithoutLogo>(
        whiteLabelSettingsSelector,
    );
    const errorToggleWhitelabelStatus = useSelector(errorToggleWhitelabelStatusSelector);

    const isMounted = useRef(false);

    const [formState, dispatch] = useReducer(
        (state: CustomizeReportFormState, action: CustomizeReportFormActionType) => {
            if (action.type === 'SET_FIELD_VALUE') {
                let newState = update(state, {
                    values: { $merge: { [action.payload.field]: action.payload.value } },
                });

                if (newState.firstTouch && state.values[action.payload.field] !== action.payload.value) {
                    newState = update(newState, {
                        values: {
                            enabled: { $set: action.payload.field === 'enabled' ? action.payload.value : true },
                        },
                        firstTouch: { $set: false },
                    });
                }

                return newState;
            } else if (action.type === 'SET_FIELD_TOUCHED') {
                return update(state, {
                    touched: { $merge: { [action.payload.field]: true } },
                });
            } else if (action.type === 'SET_FIELD_ERROR') {
                return update(state, {
                    errors: { $merge: { [action.payload.field]: action.payload.value } },
                });
            }

            return state;
        },
        settings,
        initReducer,
    );

    const onDispatch = useCallback(
        (action: CustomizeReportFormActionType) => {
            dispatch(action);
        },
        [dispatch],
    );

    const prevEnabled = useRef(formState.values.enabled);

    useEffect(() => {
        if (!isNil(errorToggleWhitelabelStatus)) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'enabled', value: prevEnabled.current } });
        }
    }, [errorToggleWhitelabelStatus]);

    useEffect(() => {
        if (isMounted.current && isNil(errorToggleWhitelabelStatus)) {
            Alert.info(`Whitelabel has been ${formState.values.enabled ? 'enabled' : 'disabled'}`);
            reduxDispatch(requestedToggleWhitelabelAction(formState.values.enabled));
        }

        reduxDispatch(clearErrorToggleWhitelabelAction());
    }, [formState.values.enabled]);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    const { values } = formState;

    return (
        <div className="uk-flex uk-height-1-1 sw-customize-report-panel">
            <CustomizeReportForm onDispatch={onDispatch} form={formState} />
            <CustomizeReportPanelPreview
                logo={values.logo}
                accentColor={values.accentColor}
                website={values.website}
                customDomain={values.customDomain}
                email={values.email}
                name={values.name}
                enabled={values.enabled}
                protocol={values.protocol}
            />
        </div>
    );
};
