import React, { Suspense, lazy } from 'react';
import { Route, IndexRoute } from 'react-router';

import AsyncLoaderErrorBoundaryContainer from 'components/AsyncLoaderErrorBoundaryContainer';
import FullAppPreloader from 'components/layout/FullAppPreloader';
import IndexContainer from 'components/index/IndexContainer';
import LayoutContainer from 'components/layout/LayoutContainer';

import RoutePaths from 'constants/RoutePaths';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';

const NewTrackingContainer = lazy(() => import('./newTracking/NewTrackingContainer'));
const TrackingContainer = lazy(() => import('components/tracking/TrackingContainer'));
const TrackingListContainer = lazy(() => import('components/trackingList/TrackingListContainer'));
const NotFound = lazy(() => import('components/other/NotFound'));

// Initialize async route handlers

/* eslint-disable react/jsx-props-no-spreading */
function LoadNewTrackingContainer(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <NewTrackingContainer {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

function LoadTrackingContainer(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <TrackingContainer {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

function LoadTrackingListContainer(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <TrackingListContainer {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

function LoadNotFound(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <NotFound {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

const fullAppRouter = (
    <Route path={RoutePaths.ROOT} component={LayoutContainer}>
        <IndexRoute component={IndexContainer} />
        <Route path={RoutePaths.NEW} component={LoadNewTrackingContainer} />
        <Route path={RoutePaths.TRACKINGS} component={LoadTrackingListContainer} />
        <Route path={RoutePaths.TRACKING} component={LoadTrackingContainer} />
        <Route path={RoutePaths.REPORT} component={LoadTrackingContainer} />
        <Route path="*" component={LoadNotFound} />
    </Route>
);

const customDomainRouter = (
    <Route path={RoutePaths.ROOT} component={LayoutContainer}>
        <IndexRoute component={() => 'Missing tracking id'} />
        <Route path={RoutePaths.REPORT} component={LoadTrackingContainer} />
        <Route path="*" component={LoadNotFound} />
    </Route>
);

const getRouter = () => (IS_CUSTOM_DOMAIN ? customDomainRouter : fullAppRouter);

/* eslint-enable react/jsx-props-no-spreading */

export default getRouter();
