import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';
import { useScript } from 'hooks/useScript';

const allowToRun = (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') && !IS_CUSTOM_DOMAIN

const appVersion = process.env.APP_VERSION;
const appHash = process.env.APP_HASH;

export const useInitialLoadScripts = () => {
    useScript(`window.VERSION = "${appVersion}"; window.VERSION_HASH = "${appHash}";`, allowToRun);

    useScript(
        `if (window.location.hash.length > 0 && window.location.hash.match(/#a/)) {
                var affiliateId = window.location.hash.substring(2);
                window.location.hash = '';
                (new Image()).src = 'https://mangools.com/affiliate/affiliates/set_cookie?affiliate_id=' + affiliateId;
            }`,
        allowToRun,
    );

    useScript(
        `var referrer = document.referrer;
            var page = window.location.href;
            (new Image()).src =  'https://mangools.com/analytics/sources/set_cookie?referrer=' +
                encodeURIComponent(referrer) + '&page=' + encodeURIComponent(page) + '&r=' + Date.now();`,
        allowToRun,
    );
};
