import React from 'react';
import { bool, func } from 'prop-types';
import { useSelector } from 'react-redux';
import { Image } from 'mangools-react-components/src';

import { currentColorSchemeSelector } from 'selectors/uiSelectors';

import MessageHolder from 'components/messages/MessageHolder';
import sadImg from 'images/sad.svg';
import sadImgDark from 'images/sad-dark.svg';
import Emoji from 'components/other/Emoji';

function WhiteLabelErrorMessage(props) {
    const colorScheme = useSelector(state => currentColorSchemeSelector(state));

    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <Image lightSrc={sadImg} darkSrc={sadImgDark} colorScheme={colorScheme} alt="Oh no" width="100" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">
                        Sorry, something went wrong <Emoji symbol="😢" label="crying-face" />
                    </h3>
                    <p>
                        Looks like there was an <strong>error in the application</strong>.<br />
                        If you see this repeatedly, contact our support please.
                    </p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

WhiteLabelErrorMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default WhiteLabelErrorMessage;
