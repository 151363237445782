import React, { useState } from 'react';
import classnames from 'classnames';

// eslint-disable-next-line max-len
import { CustomizeReportPanelReportPreview } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelReportPreview';
// eslint-disable-next-line max-len
import { CustomizeReportPanelEmailPreview } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelEmailPreview';
import { HTTP } from 'constants/Protocols';

export interface CustomizeReportPanelPreviewProps {
    logo: string;
    accentColor: string;
    customDomain: string;
    website: string;
    email: string;
    name: string;
    enabled: boolean;
    protocol: typeof HTTP;
}

enum PreviewTabTypes {
    Report = 'REPORT',
    Email = 'EMAIL',
}

export const CustomizeReportPanelPreview: React.FC<CustomizeReportPanelPreviewProps> = ({
    logo,
    accentColor,
    website,
    customDomain,
    email,
    name,
    enabled,
    protocol,
}: CustomizeReportPanelPreviewProps) => {
    const [currentPreviewTab, setCurrentPreviewTab] = useState(PreviewTabTypes.Report);

    const handleReportPreviewTabClick = () => {
        if (currentPreviewTab !== PreviewTabTypes.Report) {
            setCurrentPreviewTab(PreviewTabTypes.Report);
        }
    };

    const handleEmailPreviewTabClick = () => {
        if (currentPreviewTab !== PreviewTabTypes.Email) {
            setCurrentPreviewTab(PreviewTabTypes.Email);
        }
    };

    const isReportPreviewTab = currentPreviewTab === PreviewTabTypes.Report;

    return (
        // eslint-disable-next-line max-len
        <div className="uk-height-1-1 uk-width-1-1 uk-width-xlarge-6-10 sw-customize-report-preview mg-padding-30 uk-overflow-container">
            <div className="uk-flex uk-flex-space-between uk-flex-wrap mg-margin-b-15">
                <h2 className="uk-text-bold">Preview</h2>
                <div className="mg-btn-group uk-flex uk-flex-iteim-none">
                    <button
                        type="button"
                        className={classnames('mg-btn is-xsmall', {
                            'is-white': isReportPreviewTab,
                            'uk-text-bold': isReportPreviewTab,
                        })}
                        onClick={handleReportPreviewTabClick}
                        name={PreviewTabTypes.Report}
                    >
                        Online report
                    </button>
                    <button
                        type="button"
                        className={classnames('mg-btn is-xsmall', {
                            'is-white': !isReportPreviewTab,
                            'uk-text-bold': !isReportPreviewTab,
                        })}
                        onClick={handleEmailPreviewTabClick}
                        name={PreviewTabTypes.Email}
                    >
                        Email report
                    </button>
                </div>
            </div>
            {isReportPreviewTab ? (
                <CustomizeReportPanelReportPreview
                    logo={logo}
                    accentColor={accentColor}
                    name={name}
                    customDomain={customDomain}
                    website={website}
                />
            ) : (
                <CustomizeReportPanelEmailPreview
                    logo={logo}
                    website={website}
                    email={email}
                    customDomain={customDomain}
                    name={name}
                    enabled={enabled}
                    protocol={protocol}
                    accentColor={accentColor}
                />
            )}
        </div>
    );
};
